import React, { useState, useEffect } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import ProgressCircle from "./ProgressCircle"
import { md } from "mill/utils/breakpoints"

import POLL_CAMPAIGN_MEMBERSHIP_INITIALISED from "mill/graphql/PollCampaignMembershipInitialised"

const Initialising = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-top: 30%;
  max-width: 96rem;

  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 5rem;
    font-weight: 600;
    font-family: Raleway, sans-serif;
  }

  @media ${md} {
    padding-top: 20%;
  }
`

const YarnoLogo = styled.img`
  position: absolute;
  top: 3rem;
  left: 3rem;
  max-height: 3rem;
`

const CampaignInviteInitialisingPage = () => {
  const { campaignId } = useParams()
  const [redirect, shouldRedirect] = useState(false)
  const [percentage, setPercentage] = useState(1)

  const { data, stopPolling } = useQuery(POLL_CAMPAIGN_MEMBERSHIP_INITIALISED, {
    variables: { campaignId },
    pollInterval: 1000
  })

  useEffect(() => {
    if (
      data &&
      data.viewerCampaignMembership &&
      data.viewerCampaignMembership.initialised
    ) {
      stopPolling()
      setTimeout(() => {
        shouldRedirect(true)
      }, 1000)
    }
  }, [data, stopPolling])

  let firstNinetyFivePercentTimer = setTimeout(() => {
    setPercentage(percentage + 1)
  }, 40)

  if (percentage >= 95) {
    clearTimeout(firstNinetyFivePercentTimer)
  }

  if (redirect) return <Navigate to={`/`} />

  return (
    <Initialising>
      <YarnoLogo src={window.THEME.logoUrl} />
      <h1>Initialising...</h1>
      <ProgressCircle percentage={percentage} size="200" strokeWidth="15" />
    </Initialising>
  )
}

export default CampaignInviteInitialisingPage
