import React from "react"
import styled from "styled-components"

const BackgroundCircle = styled.circle`
  stroke: white;
  fill: none;
`

const OverlayCircle = styled.circle`
  stroke: ${props => props.theme.colors.primary};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const ProgressText = styled.text`
  font-size: 2em;
  fill: ${props => props.theme.colors.primary};
`

const ProgressCircle = props => {
  const { size, strokeWidth, percentage } = props
  const radius = (size - strokeWidth) / 2
  const viewBox = `0 0 ${size} ${size}`
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * percentage) / 100

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <BackgroundCircle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <OverlayCircle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
      <ProgressText x="50%" y="50%" dy=".3em" textAnchor="middle">
        {percentage}%
      </ProgressText>
    </svg>
  )
}

export default ProgressCircle
